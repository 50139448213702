<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="8">
          <el-form-item label="手机号">
            <el-input v-model="userPhone" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button @click="onQuery">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="orderList" highlight-current-row style="width: 100%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="payId" label="payId"></el-table-column>
      <el-table-column prop="userId" label="user_id"></el-table-column>
      <el-table-column prop="userPhone" label="user_phone"></el-table-column>
      <el-table-column prop="courseId" label="courseId"></el-table-column>
      <el-table-column prop="courseName" label="课程名"></el-table-column>
      <el-table-column prop="payChannel" label="支付渠道" :formatter="payChannelFormater"></el-table-column>
      <el-table-column prop="status" label="状态" :formatter="statusFormater"> </el-table-column>
      <el-table-column prop="createTime" :formatter="formatTime" label="创建时间"></el-table-column>
      <el-table-column prop="expireTime" :formatter="formatExpireTime" label="失效时间"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" @click="onEditOrder(scope.$index, scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="onRefundOrder(scope.row.courseId, scope.row.userPhone)"
            >退款</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { aidaOrderApi } from "@/api/aidaOrderApi";
import { orderStatusEnum } from "@/util/EnumUtil";
import { alertMessage } from "@/util/alertMessageUtil.js";
import { epochToDateString } from "@/util/TimeUtil";
import msgLogger from "@/util/msgLogger";

export default {
  data() {
    return {
      userPhone: "",
      orderList: [],
      currentPage: 1,
      totalPage: 10,
    };
  },
  mounted() {},
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    formatExpireTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    statusFormater(row, column) {
      switch (row[column.property]) {
        case orderStatusEnum.PRE_CREATE_FAIL:
          return "PRE_CREATE_FAIL";
        case orderStatusEnum.PRE_CREATE:
          return "PRE_CREATE";
        case orderStatusEnum.USER_CANCEL:
          return "USER_CANCEL";
        case orderStatusEnum.EXPIRED:
          return "EXPIRED";
        case orderStatusEnum.UNPAID:
          return "UNPAID";
        case orderStatusEnum.PAID:
          return "PAID";
        case orderStatusEnum.REFUND:
          return "REFUND";
        default:
          return row.status;
      }
    },
    payChannelFormater(row, column) {
      switch (row[column.property]) {
        case 0:
          return "none";
        case 1:
          return "user";
        case 2:
          return "cms";
        case 4:
          return "free gift";
        case 5:
          return "兑换券";
        default:
          return row.payChannel;
      }
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
    async getUserId(userPhone) {
      let qUserId = 0;
      let [ret, err] = await aidaOrderApi.awaitChain(aidaOrderApi.getAidaUserByPhone(userPhone));
      if (ret && ret.code == 0 && ret.data) {
        qUserId = ret.data.userId;
      } else {
        msgLogger.error("系统出错" + err);
      }
      return qUserId;
    },
    //获取列表
    async onQuery() {
      let qUserId = await this.getUserId(this.userPhone);
      aidaOrderApi
        .getOrderList(qUserId)
        .then((ret) => {
          if (ret.code === 0) {
            this.orderList = ret.data;
            this.totalPage = ret.totalPage;
          } else {
            alertMessage(ret.msg, "warning");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onRefundOrder(courseId, userPhone) {
      let msg = "确定删除该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let qUserId = await this.getUserId(this.userPhone);
          aidaOrderApi
            .refundOrder({
              courseId: courseId,
              userId: qUserId,
            })
            .then((ret) => {
              console.log(ret);
              if (ret.code == 0) {
                alertMessage("订单标记为退款，微信退款找凯威老师", "success");
                this.onQuery();
              } else {
                alertMessage(ret.msg, "warning");
              }
            });
        })
        .catch(() => {});
    },
    onEditOrder(index, row) {
      this.$router.push({
        name: "order-edit",
        params: { id: row.id },
      });
    },
  },
  watch: {},
};
</script>

<style scoped></style>
