import ajax from "./ajax.js";
import Qs from "qs";

let base = process.env.VUE_APP_BASE_URL;

const getAidaUserByPhone = (phone) => {
  return ajax.get(`${base}/api/itedu/v1/aida-user/get-by-phone?phone=${phone}`);
};

const saveOrder = (params) => {
  return ajax.post(`${base}/api/itedu/v1/order`, params);
};

const getOrderList = (userId) => {
  return ajax.get(`${base}/api/itedu/v1/order/query?userId=${userId}`);
};

const refundOrder = (params) => {
  return ajax.post(`${base}/api/itedu/v1/order/refund`, Qs.stringify(params));
};

const getOrderById = (id) => {
  return ajax.get(`${base}/api/itedu/v1/order?id=${id}`);
};

const getHistoryOrderList = (phone) => {
  return ajax.get(`${base}/api/itedu/v1/history-order/query?phone=${phone}`);
};

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const aidaOrderApi = {
  getAidaUserByPhone: getAidaUserByPhone,
  saveOrder: saveOrder,
  getOrderList: getOrderList,
  refundOrder: refundOrder,
  getOrderById: getOrderById,
  getHistoryOrderList: getHistoryOrderList,
  awaitChain: awaitChain,
};
